<template>
    <div class="wrapper flex flex-col h-full pt-8">
        <div class="flex items-center justify-center mb-16">
            <svg xmlns="http://www.w3.org/2000/svg" width="80" height="39" fill="none">
                <path fill="#710666"
                    d="M28.975.257H0v38.728h6.702V6.646H26.02L28.975.257Zm7.078-.254L27.02 19.975H12.066v6.403H24.12l-5.707 12.605h7.067l5.629-12.605h9.555l-2.854-6.402H33.97l4.782-10.694 13.175 29.703h7.275L41.565 0l-5.512.003ZM80 13.163v-.101c0-3.374-1.273-6.914-3.17-8.81C74.252 1.676 70.278.257 65.64.257H48.41l2.929 6.415 13.995-.022c4.8.019 7.91 2.598 7.92 6.564v.105c0 4.086-2.854 6.632-7.434 6.647h-8.53l2.928 6.415h4.144l7.045 12.604h8.091L71.48 25.073c5.301-1.637 8.528-6.085 8.519-11.91Z" />
            </svg>
        </div>
        <div class="px-5 mb-6">
            <h1 class="text-[36px] leading-[1.1] font-medium text-purple text-center shadowed-text mb-5">Fyll i dina
                uppgifter för att få ditt<br>resultat</h1>
            <p class="text-center text-darkPurple text-[18px] leading-[1.6]">Snyggt jobbat och tack för ditt deltagande!
                Fyll i dina uppgifter för att ta del av och registrera ditt resultat.</p>
        </div>

        <div class="bg-lightPurple px-5 pt-8 pb-28 rounded-t-3xl mt-auto">
            <form @submit.prevent="submit">
                <div class="flex flex-col gap-2 mb-6">

                    <TextInput v-model="firstname" placeholder="Förnamn"></TextInput>
                    <p v-if="firstnameError" class="text-red">Fyll i ditt förnamn.</p>

                    <TextInput v-model="lastname" placeholder="Efternamn"></TextInput>
                    <p v-if="lastnameError" class="text-red">Fyll i ditt efternamn.</p>

                    <TextInput v-model="company" placeholder="Företag"></TextInput>
                    <p v-if="companyError" class="text-red">Fyll i ditt företag.</p>

                    <EmailInput v-model="email" placeholder="E-post"></EmailInput>
                    <p v-if="emailError" class="text-red">Fyll i din e-post.</p>

                    <label class="flex flex-row gap-2 items-center justify-center">
                        <input v-model="consent" type="checkbox">
                        <span class="text-pink text-[18px] leading-[1.6]">Jag godkänner att mina uppgifter sparas</span>
                    </label>
                </div>
                <Button text="Se resultat" :disabled="submitted" @click="showResults"></Button>
            </form>
        </div>
    </div>
</template>

<script>

import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { questions } from '../data/questions'
import Button from './Button.vue'
import TextInput from './TextInput.vue'
import EmailInput from './EmailInput.vue'

export default {
    name: 'RegisterScreen',
    components: {
        Button,
        TextInput,
        EmailInput,
    },
    setup() {
        const router = useRouter()
        const store = useStore()
        let firstname = ref('')
        let lastname = ref('')
        let company = ref('')
        let email = ref('')
        let consent = ref(false)
        let firstnameError = ref(false)
        let lastnameError = ref(false)
        let companyError = ref(false)
        let emailError = ref(false)
        let submitted = ref(false)

        function showResults() {
            submitted.value = true;

            firstnameError.value = !firstname.value;
            lastnameError.value = !lastname.value;
            emailError.value = !email.value;
            companyError.value = !company.value;

            if (!firstnameError.value && !lastnameError.value && !emailError.value && consent.value) {
                store.commit('setFirstname', firstname.value)
                store.commit('setLastname', lastname.value)
                store.commit('setEmail', email.value)
                store.commit('setCompany', company.value)

                // Proceed with showing results
                saveResult();  // Assuming saveResult is the function to process results
            } else {
                submitted.value = false;
            }
        }

        function saveResult() {

            var data = {
                action: 'SaveResult',
                firstname: store.getters.firstname,
                lastname: store.getters.lastname,
                email: store.getters.email,
                company: store.getters.company,
                finalscore: store.getters.finalScore,
                elapsedtime: store.getters.elapsedTime,
                answers: JSON.stringify(store.getters.answers),
            }

            // Send data to server
            fetch(ajaxUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                body: new URLSearchParams(data),
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log(data)
                    if (data.success) {
                        //Next screen
                        store.commit('userId', data.userId)
                        getLeaderBoard()
                        router.push({ name: 'result' })
                    } else {
                        alert(data.message)  // Display error message
                    }
                })
                .catch((error) => {
                    console.error('Error:', error)
                })
        }

        function getLeaderBoard() {
            var data = {
                action: 'GetLeaderBoard',
                userId: store.getters.userId
            }

            fetch(ajaxUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                body: new URLSearchParams(data),
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log(data)
                    if (data.success) {
                        store.commit('leaderboard', data.leaderboard)
                        store.commit('isCurrentUserFirst', data.isCurrentUserFirst)
                    }
                })
                .catch((error) => {
                    console.error('error:', error)
                })
        }

        onMounted(() => {
            if (store.getters.answers.length < questions.length) {
                console.log('User has not completed the quiz, return to frontpage')
                router.push({ name: 'start' })
            }
        })

        return {
            showResults,
            saveResult,
            firstname,
            lastname,
            email,
            company,
            consent,
            firstnameError,
            lastnameError,
            companyError,
            emailError,
            submitted,
            questions
        }
    }
}
</script>