// src/store/index.js
import { createStore } from 'vuex'

export default createStore({
    state() {
        return {
            finalScore: 0,
            elapsedTime: '00:00:00',
            answers: [],
            userId: null,
            firstname: '',
            lastname: '',
            email: '',
            company: '',
            leaderboard: [],
            isCurrentUserFirst: false,
            transitionName: 'slide-left'
        }
    },
    mutations: {
        setFirstname(state, firstname) {
            state.firstname = firstname
        },
        setLastname(state, lastname) {
            state.lastname = lastname
        },
        setEmail(state, email) {
            state.email = email
        },
        setCompany(state, company) {
            state.company = company
        },
        elapsedTime(state, time) {
            state.elapsedTime = time
        },
        setFinalScore(state, score) {
            state.finalScore = score
        },
        setElapsedTime(state, time) {
            state.elapsedTime = time
        },
        setAnswers(state, answers) {
            state.answers = answers
        },
        resetQuiz(state) {
            state.finalScore = 0
            state.elapsedTime = ''
            state.answers = []
        },
        userId(state, id) {
            state.userId = id
        },
        leaderboard(state, data) {
            state.leaderboard = data
        },
        isCurrentUserFirst(state, data) {
            state.isCurrentUserFirst = data
        },
        setTransition(state, transitionName) {
            state.transitionName = transitionName
        }
    },
    actions: {
        submitQuiz({ commit }, { score, time, answers }) {
            commit('setFinalScore', score)
            commit('setElapsedTime', time)
            commit('setAnswers', answers)
        },
        resetQuiz({ commit }) {
            commit('resetQuiz')
        },
        updateTransition({ commit }, transitionName) {
            commit('setTransition', transitionName)
        }
    },
    getters: {
        firstname(state) {
            return state.firstname
        },
        lastname(state) {
            return state.lastname
        },
        email(state) {
            return state.email
        },
        company(state) {
            return state.company
        },
        finalScore(state) {
            return state.finalScore
        },
        elapsedTime(state) {
            return state.elapsedTime
        },
        answers(state) {
            return state.answers
        },
        userId(state) {
            return state.userId
        },
        leaderboard(state) {
            return state.leaderboard
        },
        isCurrentUserFirst(state) {
            return state.isCurrentUserFirst
        },
        transitionName(state) {
            return state.transitionName
        }
    }
})
