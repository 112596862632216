<template>
    <div class="wrapper flex flex-col h-full pt-8">
        <div class="flex items-center justify-center mb-6">
            <svg xmlns="http://www.w3.org/2000/svg" width="80" height="39" fill="none">
                <path fill="#710666"
                    d="M28.975.257H0v38.728h6.702V6.646H26.02L28.975.257Zm7.078-.254L27.02 19.975H12.066v6.403H24.12l-5.707 12.605h7.067l5.629-12.605h9.555l-2.854-6.402H33.97l4.782-10.694 13.175 29.703h7.275L41.565 0l-5.512.003ZM80 13.163v-.101c0-3.374-1.273-6.914-3.17-8.81C74.252 1.676 70.278.257 65.64.257H48.41l2.929 6.415 13.995-.022c4.8.019 7.91 2.598 7.92 6.564v.105c0 4.086-2.854 6.632-7.434 6.647h-8.53l2.928 6.415h4.144l7.045 12.604h8.091L71.48 25.073c5.301-1.637 8.528-6.085 8.519-11.91Z" />
            </svg>
        </div>

        <div class="text-center">
            <p class="text-center text-darkPurple text-[16px] leading-[1.6] mb-1.5">Ditt resultat:</p>
            <p v-if="!isCurrentUserFirst"
                class="text-[36px] leading-[1.1] font-medium text-purple text-center shadowed-text">{{ finalScore }}/{{
                    questions.length }}
            </p>
            <p v-if="isCurrentUserFirst"
                class="text-[36px] leading-[1.1] font-medium text-purple text-center shadowed-text flex flex-row justify-center items-center gap-2">
                <svg width="19" height="27" viewBox="0 0 19 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.5002 5.5L7.00024 1M18.0561 14L1 13M15.5113 22.2321L5.00023 26" stroke="#710666"
                        stroke-width="1.5" stroke-linecap="round" />
                </svg>
                {{ finalScore }}/{{ questions.length }}
                <svg width="19" height="27" viewBox="0 0 19 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.5559 5.5L12.0559 1M1 14L18.0561 13M3.54482 22.2321L14.0559 26" stroke="#710666"
                        stroke-width="1.5" stroke-linecap="round" />
                </svg>
            </p>

            <p class="text-darkPurple text-[18px] leading-[1.6] font-medium shadowed-text mb-1.5">{{ elapsedTime }}</p>
            <p v-if="!isCurrentUserFirst" class="text-center text-darkPurple text-[18px] leading-[1.6]">Tyvärr blev du
                inte:</p>
            <p v-if="isCurrentUserFirst" class="text-center text-darkPurple text-[18px] leading-[1.6]">Gratulerar, du
                är:</p>
            <p class="body-lg text-darkPurple text-center shadowed-text">Smartast på ekonomimässan.</p>
            <svg class="mx-auto mb-8" width="192" height="12" viewBox="0 0 192 12" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1H191L21.0962 9.5L155.071 11" stroke="#710666" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
            </svg>
        </div>

        <div class="px-5 mb-6">
            <div class="bg-pink rounded-2xl py-6 px-5">
                <p class="text-yellow text-[16px] leading-[1.6] mb-2">TOPP TIO</p>
                <ol class="list-decimal list-inside" v-if="leaderboard">
                    <li class="text-yellow text-[16px] leading-[1.6] mb-2 last:mb-0"
                        :class="{ 'text-[18px] leading-[1.6] font-medium': participant.isCurrentUser }"
                        v-for="participant in leaderboard" :key="participant.id">
                        {{ participant.name }}
                    </li>
                </ol>
            </div>
        </div>

        <div class="px-5 pb-6 text-center">
            <a class="body-lg text-darkPurple text-center shadowed-text"
                href="https://www.linkedin.com/school/far_2/">Följ oss på <span
                    class="underline">LinkedIn</span></a>
            <svg class="mx-auto mb-8" width="192" height="12" viewBox="0 0 192 12" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1H191L21.0962 9.5L155.071 11" stroke="#710666" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round"></path>
            </svg>
        </div>

    </div>
</template>

<script>
import { computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { questions } from '../data/questions'

import Button from './Button.vue'

export default {
    name: 'ResultScreen',
    components: {
        Button,
    },
    setup() {
        const store = useStore()
        const router = useRouter()
        const elapsedTime = computed(() => store.getters.elapsedTime)
        const finalScore = computed(() => store.getters.finalScore)
        const leaderboard = computed(() => store.getters.leaderboard)
        const isCurrentUserFirst = computed(() => store.getters.isCurrentUserFirst)

        onMounted(() => {
            if (store.getters.answers.length < questions.length) {
                console.log('User has not completed the quiz, return to frontpage')
                router.push({ name: 'start' })
            }
        })

        return {
            elapsedTime,
            finalScore,
            leaderboard,
            isCurrentUserFirst,
            questions
        }

    }
}
</script>
